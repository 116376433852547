import React from "react";
import {Link} from "gatsby";

const Footer = ({instagram, linkedin, mail}) => (
  <footer id='main-footer'>
    <div id='footer-container' className='inner-container'>

      <div className="left">
        <div className="footer-up">
          {/* <span>&#8593;</span>  */}
        </div>
      </div>
      <div className="right">
        <div className='footer-nav'>
          <ul>
            <li><Link to="/travaux/">Travaux</Link></li>
            <li><Link to="/a-propos/">À Propos</Link></li>
            <li><Link to="/contact/">Contact</Link></li>
          </ul>
        </div>
        <div className='footer-social'>
          <ul>
            <li><a href={instagram} target='_blank' rel='noopener noreferrer'>Instagram</a></li>
            <li><a href={linkedin} target='_blank' rel='noopener noreferrer'>LinkedIn</a></li>
            <li><a href={ `mailto:${mail}` } target='_blank' rel='noopener noreferrer'>Email</a></li>
          </ul>
        </div>
        <div className='footer-info'>
          <div>© 2020 Tous droits réservés | Marine Fargetton</div>
          <div>Design et Développement &#8594; <a href="http://www.romaricfargetton.com">Romaric Fargetton</a></div>
        </div>
      </div>

    </div>   
  </footer>
)

export default Footer;

