import React from "react";
import PropTypes from 'prop-types';
import {Link} from "gatsby";

const Logo = ({ color }) => (
  <svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.43 326.41">
    <path fill={color} d="M318.1 111.72a4.53 4.53 0 0 0-.28-.86A163.24 163.24 0 0 0 163.21-.01 161.16 161.16 0 0 0 89.32 17.7a165.16 165.16 0 0 0-56.21 47.07 4.53 4.53 0 0 0-1 1.27A163.23 163.23 0 0 0 161.29 326.4h3.8a163.52 163.52 0 0 0 152.69-110.84 4.5 4.5 0 0 0 .28-.86 163.34 163.34 0 0 0 .04-102.98zm-150.39 93v-83a4.5 4.5 0 0 1 4.5-4.5h134.24a5.29 5.29 0 0 1 5.09 3.79 154.39 154.39 0 0 1 .4 83.07 7.09 7.09 0 0 1-6.82 5.2H172.23a4.52 4.52 0 0 1-4.52-4.56zm138-97.57a2.25 2.25 0 0 1-1.92 1h-132.1a4 4 0 0 1-4-4V13.02a3.78 3.78 0 0 1 1.19-2.77 3.82 3.82 0 0 1 2.67-1.06h.23c59 3.26 111.69 40.82 134.18 95.68a2.32 2.32 0 0 1-.2 2.29zM171.52 218.23H303a2.86 2.86 0 0 1 2.63 4 154.51 154.51 0 0 1-134.15 95 3.65 3.65 0 0 1-2.71-1 3.53 3.53 0 0 1-1.11-2.59v-91.5a3.87 3.87 0 0 1 3.92-3.9zm-86.55-50l.32-.11a3.54 3.54 0 0 0 .5-.19 2.46 2.46 0 0 0 .3-.17l.2-.13a4.47 4.47 0 0 0 .68-.55l.18-.18a4.52 4.52 0 0 0 .5-.68l.06-.08 71-142.46v289.56a3.84 3.84 0 0 1-1.21 2.82 3.93 3.93 0 0 1-2.95 1.07A154.14 154.14 0 0 1 33.75 79.47a1.8 1.8 0 0 1 1.53-.84h.11a1.75 1.75 0 0 1 1.5 1l43 86.34.06.1a4.41 4.41 0 0 0 .56.8 2 2 0 0 0 .19.19 4.53 4.53 0 0 0 .7.57l.18.11.27.16a3.22 3.22 0 0 0 .49.19l.39.13a4.41 4.41 0 0 0 2.3.05zm69.8-156.77L83.86 153.84 43.71 73.17a7.77 7.77 0 0 1 .93-8.43A154.83 154.83 0 0 1 153.33 9.32h.11a1.47 1.47 0 0 1 1.25.67 1.44 1.44 0 0 1 .08 1.47z"/>
  </svg>
)

const Header = ({ title }) => (

    <header id='main-header'>
      <div id="header-container" className='inner-container'>
        <nav>
            <ul>
              <li className='brand'><Link activeClassName='active' to="/">
                  <Logo color='black'/>
                  <div className='title'>{title}</div>
              </Link></li>
              <li><Link activeClassName='active' to="/travaux/">Travaux</Link></li>
              <li><Link activeClassName='active' to="/a-propos/">À Propos</Link></li>
              <li><Link activeClassName='active' to="/contact/">Contact</Link></li>
            </ul>
        </nav>
      </div>
    </header>          

)

export default Header
Header.propTypes = {
    title : PropTypes.string
}
