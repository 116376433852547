import React from 'react';
import Header from './header.js';
import Footer from './footer.js';
import Helmet from 'react-helmet';
import {graphql, StaticQuery} from 'gatsby';

const Layout = ({children, data, wrapperClassName}) => (
    <StaticQuery 
        query = {query}
        render = {({site:{siteMetadata:{title, description}}, wordpressPage:{acf:{linkedin_url, instagram_url, mail_url}}}) => {

            return (
                <div>
                    <Helmet 
                        defaultTitle = {title + ' | ' + description} 
                        titleTemplate = {title + ' | %s'} 
                    >
                      <html lang='fr'/>
                      <meta name='description' content={description} />
                    </Helmet>            
                    <Header title={title} />
                    <main className='main-container'>
                      {children}
                    </main>
                    <Footer instagram={instagram_url}
                            linkedin={linkedin_url}
                            mail={mail_url}/>
                </div>                
            )

        }}
           
    />

)

export default Layout 
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        wordpressPage (slug: {eq: "home"} ) {
            acf {
                linkedin_url
                instagram_url
                mail_url
            }
        }
    }
`
